import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, ModalController } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ServiceInjector } from './services/utils/injector.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment, stripeApiKey } from '../environments/environment';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './shared/store/user/user.effects';
import { BearerInterceptor } from './shared/interceptors/bearer.interceptor';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ApiModule } from './shared/openapi';
import { ConvertCaseInterceptor } from './shared/interceptors/convert-keys.interceptor';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UnauthorizedInterceptor } from './shared/interceptors/unauthorized.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandlerInterceptor } from '@app/shared/interceptors/error-handler.interceptor';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { authFeature } from '@app/shared/store/user/user.reducer';
import { ShowForRolePipe } from '@app/shared/store/user/utils/pipes/role.pipe';
import { NgxStripeModule } from 'ngx-stripe';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function playerFactory() {
  return player;
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollPadding: false,
      scrollAssist: false,
    }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register(
      //'ngsw-worker.js',
      'combined-sw.js',
      {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      },
    ),
    LottieModule.forRoot({ player: playerFactory }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    IonicStorageModule.forRoot(),
    ApiModule,
    ShowForRolePipe,
    NgxStripeModule.forRoot(stripeApiKey),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConvertCaseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private injector: Injector,
    private modalCtrl: ModalController,
  ) {
    // Create global Service Injector.
    ServiceInjector.injector = this.injector;
    ServiceInjector.modalCtrl = this.modalCtrl;
  }
}
